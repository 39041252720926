/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Se puede de nuevo pedir muestras gratuitas en la pagina de microchip, no se desde cuando porque a mi se me ha ocurrido mirar esta tarde, esta vez solo 2 muestras por carta pero menos da una piedra. Yo ya he pedido un PIC16F877A y un PIC16F88, a ver cuanto tardan en traérmelos.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
